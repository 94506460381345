import avertaBold from 'assets/fonts/averta/averta-bold.woff2';
import avertaExtrabold from 'assets/fonts/averta/averta-extrabold.woff2';
import avertaRegular from 'assets/fonts/averta/averta-regular.woff2';
import { urlModifier } from 'swag-client-common/libs/url-modifier';
const createFontLink = href => {
  const fontLink = document.createElement('link');
  fontLink.setAttribute('as', 'font');
  fontLink.setAttribute('rel', 'preload');
  fontLink.setAttribute('type', 'font/woff2');
  fontLink.setAttribute('href', urlModifier(href));
  fontLink.setAttribute('crossorigin', '');
  return fontLink;
};
export const getFonts = () => {
  document.head.prepend(createFontLink(avertaBold));
  document.head.prepend(createFontLink(avertaExtrabold));
  document.head.prepend(createFontLink(avertaRegular));
};